import { toastNotifyError, toastNotifyInfo } from '@/components/Common/Toast';
import { resizedataURL } from '@/components/WidgetMaker/WidgetsComponents/CommonComponents/canvasUtils';
import { closeProductPersonalizationPopUp } from '@/redux/actions';
import store from '@/redux/store';
import { itemQuantityValueComparison } from '@/utils/itemQuantityValueComparison';

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const validatePersonalization = (images, textData, productCustomizationCount) => {
  const hasNullImages = images?.some((image) => image === null);
  const noImagesUploaded = images?.every((image) => image === null);

  const validationStatus = {
    status: true,
    images: true,
    text: true,
  };

  // Check if image is required and cx uploads no image
  if (productCustomizationCount?.imageCount && noImagesUploaded) {
    validationStatus.status = false;
    validationStatus.images = false;
  } else if (
    productCustomizationCount?.imageCount &&
    productCustomizationCount?.allImageMandatory &&
    hasNullImages
  ) {
    // Check if image count is mandatory and all images must be uploaded
    validationStatus.status = false;
    validationStatus.images = false;
  }

  if (productCustomizationCount?.textCount > 0 && textData?.length === 0) {
    validationStatus.status = false;
    validationStatus.text = false;
  }

  return validationStatus;
};

export const getHeadingText = (showInitialScreen, personalizedProduct) => {
  if (showInitialScreen() && personalizedProduct?.editPersonalizationPopUp) {
    return 'Edit personalisation';
  } else if (showInitialScreen() && !personalizedProduct?.editPersonalizationPopUp) {
    return 'Personalise this product';
  }
  return personalizedProduct?.actionType === 'inc'
    ? 'Repeat last used personalisation?'
    : 'Your personalisations';
};

export const filterNull = (item, checkType) => item?.filter?.((ele) => ele !== checkType);

export const initializeState = (personalizedProduct, imageCount) => {
  const newImages =
    personalizedProduct?.editableData?.images ??
    Array.from({ length: imageCount }, () => null);
  const newTextData = personalizedProduct?.editableData?.texts ?? [''];
  return { newImages, newTextData };
};

function blobToByteArray(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Event handler for when the Blob has been read successfully
    reader.onload = function (event) {
      const arrayBuffer = event.target.result;
      // Create a byte array from the ArrayBuffer
      const byteArray = new Uint8Array(arrayBuffer);
      resolve(byteArray);
    };

    // Event handler for errors
    reader.onerror = function (error) {
      reject(error);
    };

    // Read the Blob as an ArrayBuffer
    reader.readAsArrayBuffer(blob);
  });
}

function byteArrayToBase64(byteArray) {
  // Convert the byte array to a string
  let binary = '';
  const len = byteArray.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(byteArray[i]);
  }
  // Convert the binary string to a base64 encoded string
  return btoa(binary);
}

export const handleImageUpload = async (
  e,
  idx,
  images,
  setImages,
  setErrorState,
  tempImages,
  setTempImages
) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB >= 5) {
      toastNotifyError('File size should be less than 5MB');
      return;
    }

    const imageDataUrl = await readFile(file);
    const imageType = imageDataUrl?.split(';')[0]?.split(':')[1];
    const convertImageToBlob = await resizedataURL(imageDataUrl, 0, 0, imageType);
    const formData = new FormData();
    formData.append('media_type', 'store_logo');
    formData.append('store_id', store.getState().storeReducer.store?.store_id);
    formData.append('media', convertImageToBlob);

    blobToByteArray(convertImageToBlob)
      .then((byteArray) => {
        const str = byteArrayToBase64(byteArray);
        const newImages = [...images];
        newImages[idx] = str;
        setImages(newImages);
        const newTempImages = [...tempImages];
        newTempImages[idx] = imageDataUrl;
        setTempImages(newTempImages);
        setErrorState('');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
};

export const handleImageRemove = (idx, images, setImages, tempImages, setTempImages) => {
  const newImageList = [...images];
  newImageList[idx] = null;
  const newTempImageList = [...tempImages];
  newTempImageList[idx] = null;
  setTempImages(newTempImageList);
  setImages(newImageList);
};

export const handleTextDataChange = (e, textCount, setTextData, setErrorState) => {
  let newText = e.target.value;
  // Truncate the text if it exceeds textCount
  if (newText.length > textCount) {
    newText = newText.slice(0, textCount);
  }
  setTextData([newText]);
  setErrorState('');
};

export const checkIsCtaActive = (images, textData) => {
  const hasNonNullImages = images.some((image) => image !== null);
  const hasTextData = textData[0]?.length > 0;
  return hasNonNullImages || hasTextData;
};

export const checkIfIncrementAllowed = (type, productData, cartData) => {
  const isIncrementAction = type === 'add' || type === 'inc';

  if (!isIncrementAction) return true;

  const hasVariants = productData?.variants_count || productData?.variants?.length > 0;

  // Variants case

  if (hasVariants) {
    const activeVariantId = localStorage.getItem('activeVariantId');
    const activeVariant = productData?.variants?.find(
      (el) => `${el?.variant_id}` === activeVariantId
    );

    if (itemQuantityValueComparison(activeVariant)) {
      toastNotifyInfo('No more items available for purchase');
      return false;
    }

    return true;
  }

  // Case of item with no variants
  const itemIndexInCart = cartData?.items?.findIndex(
    (el) => (el.id || el.item_id) === (productData?.id || productData?.item_id)
  );

  const productItem = cartData?.items?.[itemIndexInCart];

  if (itemQuantityValueComparison(productItem)) {
    toastNotifyInfo('No more items available for purchase');
    return false;
  }

  return true;
};

export const handleCartUpdate = (
  type,
  data,
  ppIdx,
  handleAddPersonalizationToLocalState,
  images,
  textData,
  imageCount,
  textCount,
  allImageMandatory,
  personalizedProduct,
  handleCart,
  dispatch,
  productData,
  source,
  setImages,
  setTextData,
  setErrorState,
  cartData
) => {
  const imageData = data?.images || images;
  const text = data?.texts?.[0] || textData?.[0];
  const {
    status,
    images: imageStatus,
    text: textStatus,
  } = validatePersonalization(imageData, text, {
    imageCount,
    textCount,
    allImageMandatory,
  });

  if (!status) {
    if (!imageStatus && !textStatus) {
      setErrorState('Please complete all personalisation details');
    } else if (!imageStatus) {
      const errorText = allImageMandatory
        ? 'Please upload all images.'
        : 'At least one image is required';
      setErrorState(errorText);
    } else {
      setErrorState('Please enter text for personalisation');
    }
    return;
  }

  if (!checkIfIncrementAllowed(type, productData, cartData)) {
    dispatch(closeProductPersonalizationPopUp());
    return;
  }

  const productPersonalizationPayload = {
    images: data?.images || images.filter((ele) => ele !== null),
    texts: data?.texts || textData.filter((ele) => ele !== ''),
    quantity: data?.[ppIdx]?.quantity ?? 1,
  };
  if (type === 'add') {
    handleAddPersonalizationToLocalState(productPersonalizationPayload);
  }

  handleCart(type, productPersonalizationPayload, ppIdx, productData, source);

  if (type === 'save') {
    dispatch(closeProductPersonalizationPopUp());
    setImages(Array.from({ length: imageCount }, () => null));
    setTextData(['']);
  }
};

export const handleByNowClick = (
  images,
  textData,
  imageCount,
  textCount,
  allImageMandatory,
  onBuyNowClick,
  setImages,
  setTextData,
  setErrorState
) => {
  const {
    status,
    images: imageStatus,
    text: textStatus,
  } = validatePersonalization(images, textData[0], {
    imageCount,
    textCount,
    allImageMandatory,
  });

  if (!status) {
    if (!imageStatus && !textStatus) {
      setErrorState('Please complete all personalisation details');
    } else if (!imageStatus) {
      setErrorState('Please upload all images.');
    } else {
      setErrorState('Please enter text for personalisation');
    }
    return;
  }

  const productPersonalizationPayload = {
    images: images.filter((ele) => ele !== null),
    texts: textData.filter((ele) => ele !== ''),
    quantity: 1,
  };

  onBuyNowClick(productPersonalizationPayload);
  setImages(Array.from({ length: imageCount }, () => null));
  setTextData(['']);
};
