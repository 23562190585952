import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastNotifyInfo } from '@/components/Common/Toast';
import {
  openProductPersonalizationPopUp,
  editProductPersonalization,
  closeProductPersonalizationPopUp,
} from '../../../redux/actions';
import {
  CartMinusIcon,
  CartPlusIcon,
  RightArrowIcon,
  PlusIcon,
} from '../utils/SvgExport';
import ReadMoreReadLess from './ReadMoreReadLess';
import { IS_CLIENT } from '@/utils/checkRenderEnv';

const IncrementProductPersonalizationScreen = ({
  cartData,
  personalizedProduct,
  setImages,
  setTempImages,
  setTextData,
  imageCount,
  handleCart,
  productData,
  source,
  personalizationLocalState,
  setPersonalizationLocalState,
  storeData,
}) => {
  const dispatch = useDispatch();

  const [showConfirmCTA, setShowConfirmCTA] = useState(false);

  const handleEditPersonalization = ({
    data = {},
    personalizedProductData = {},
    idx = null,
  }) => {
    const filledImages = new Array(imageCount)
      .fill(null)
      .map((_, i) => data?.images[i] || null);
    setImages(filledImages);
    setTempImages(filledImages);
    setTextData(data?.texts);
    dispatch(
      editProductPersonalization({
        personalizedProductData: personalizedProductData
          ? { ...personalizedProductData, index: idx }
          : {},
      })
    );
  };

  function getPersonalizationTotalQuantity() {
    return personalizationLocalState?.reduce(
      (sum, item) => sum + (item.quantity || 0),
      0
    );
  }

  const allowItemAddition = () => {
    const itemIndexInCart = cartData?.items?.findIndex(
      (el) => (el?.id || el?.item_id) === (productData?.id || productData?.item_id)
    );
    const productItem = (cartData?.items && cartData.items[itemIndexInCart]) || null;
    const totalQuantity = getPersonalizationTotalQuantity();

    if (productItem?.variants_count || !!productItem?.variants_selected?.length) {
      const variantsPresentInCart = productItem?.variants_selected || [];
      const activeVariantId = IS_CLIENT && localStorage.getItem('activeVariantId');
      const activeVariant = variantsPresentInCart?.find(
        (variant) => `${variant?.variant_id}` === activeVariantId
      );

      if (activeVariant?.managed_inventory) {
        return totalQuantity < activeVariant?.available_quantity;
      }
    } else {
      if (
        productItem?.managed_inventory &&
        totalQuantity >= productItem?.available_quantity
      ) {
        return false;
      }
    }
    return true;
  };

  const updateLocalQuantity = (idx, type) => {
    setPersonalizationLocalState((prevState) => {
      const newState = [...prevState];
      const item = { ...newState[idx] };

      if (type === 'inc') {
        if (allowItemAddition()) {
          item.quantity = (item.quantity || 0) + 1;
          setShowConfirmCTA(true);
        } else {
          toastNotifyInfo('No more units available for purchase');
        }
      } else if (type === 'dec') {
        item.quantity = Math.max((item.quantity || 0) - 1, 0);
        setShowConfirmCTA(true);
      }
      newState[idx] = item;
      return newState;
    });
  };

  const handleConfirmChanges = () => {
    handleCart('confirm', null, null, productData, source, personalizationLocalState);
    dispatch(closeProductPersonalizationPopUp());
    setShowConfirmCTA(false);
    setPersonalizationLocalState([]);
  };

  function addNewPersonalization() {
    if (!allowItemAddition()) {
      toastNotifyInfo('Unable to add new personalization as item limits exceeded');
      return;
    }
    dispatch(openProductPersonalizationPopUp());
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-[1.5rem] tw-rounded-lg tw-shadow-[2px_2px_8px_0px_#0000000a]">
      <div className=" tw-flex tw-flex-col tw-rounded-[8px] tw-bg-[#fff] tw-pb-[1.25rem]">
        <div className="tw-flex-tw-flex-col">
          {personalizationLocalState?.map((data, idx) => {
            const text = data?.texts?.[0];
            return (
              <div
                key={idx}
                className="tw-flex tw-gap-[1.5rem] tw-border-b tw-border-solid tw-border-[#d9d9d9] tw-pb-[1.125rem] tw-pt-[1.25rem]"
              >
                <div className="tw-flex tw-w-[75%] tw-flex-col tw-gap-[0.5rem] tw-pl-[1rem] md:tw-gap-[0.75rem]">
                  <span className="tw-text-[0.75rem] tw-font-semibold tw-leading-5 tw-text-[#0E0E0E] md:tw-text-[14px]">
                    {personalizedProduct?.personalizationData?.item_name}
                  </span>
                  {!!data?.images?.length && (
                    <div className="tw-flex tw-flex-wrap tw-gap-[0.75rem]">
                      {data?.images?.map((imageDataUrl, idx) => (
                        <img
                          src={imageDataUrl}
                          key={idx}
                          alt="product-image"
                          width="46px"
                          height="48px"
                          className="tw-rounded tw-border tw-border-solid tw-border-[#0000001A]"
                        />
                      ))}
                    </div>
                  )}
                  {!!text?.length && (
                    <div>
                      <ReadMoreReadLess text={text} color="#4E4E4E" />
                    </div>
                  )}
                  <div
                    className="tw-flex tw-items-center"
                    onClick={() =>
                      handleEditPersonalization({
                        data,
                        personalizedProductData: {
                          ...personalizedProduct?.personalizationData,
                          quantity: data?.quantity,
                        },
                        idx,
                      })
                    }
                  >
                    <span className="tw-flex tw-cursor-pointer tw-items-center tw-text-[0.875rem] tw-font-semibold tw-text-[#4E4E4E]">
                      Edit personalisation
                    </span>
                    <span className="tw-flex tw-items-center tw-pt-[2px]">
                      <RightArrowIcon />
                    </span>
                  </div>
                </div>
                <div className="tw-fl-col tw-flex tw-w-[25%] tw-flex-col tw-gap-[1.125rem] tw-pr-[1rem]">
                  <div className="tw-flex tw-w-[92px] tw-items-center tw-justify-between tw-gap-[0.625rem] tw-self-end tw-rounded tw-border tw-border-solid tw-border-[#000] tw-p-[0.5rem] tw-text-[0.875rem] tw-font-normal">
                    <span
                      className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center"
                      onClick={() => updateLocalQuantity(idx, 'dec')}
                    >
                      <CartMinusIcon />
                    </span>
                    <span>{personalizationLocalState?.[idx]?.quantity}</span>
                    <span
                      className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center"
                      onClick={() => updateLocalQuantity(idx, 'inc')}
                    >
                      <CartPlusIcon color={allowItemAddition() ? '#0E0E0E' : '#ccc'} />
                    </span>
                  </div>
                  <span className="tw-self-end tw-text-[0.875rem] tw-font-normal tw-leading-5">
                    ₹
                    {personalizedProduct?.personalizationData?.discounted_price *
                      personalizationLocalState?.[idx]?.quantity}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="tw-mt-[1.125rem] tw-flex tw-items-center tw-justify-center">
          <span
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-[0.5rem]"
            onClick={addNewPersonalization}
            style={
              !allowItemAddition()
                ? {
                    opacity: 0.3,
                    color: storeData?.theme?.colors?.primary_color || '#1F1F1F',
                  }
                : { color: storeData?.theme?.colors?.primary_color || '#1F1F1F' }
            }
          >
            <PlusIcon color={storeData?.theme?.colors?.primary_color || '#000'} />
            <span className="tw-text-[0.875rem] tw-font-semibold">
              Add a new personalisation
            </span>
          </span>
        </div>
      </div>
      {showConfirmCTA && (
        <button
          className="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-border-none tw-bg-black tw-py-[0.75rem] tw-text-[0.875rem] tw-font-medium tw-text-[#fff]"
          onClick={handleConfirmChanges}
          style={{ backgroundColor: storeData?.theme?.colors?.primary_color || 'black' }}
        >
          Confirm
        </button>
      )}
    </div>
  );
};

export default IncrementProductPersonalizationScreen;
