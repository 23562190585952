import React from 'react';
import { useDispatch } from 'react-redux';

import ImageUpload from './ImageUploadSection';
import TextBox from './TextBox';
import { ErrorIcon } from '../utils/SvgExport';
import { RenderProductCTA } from './RenderProductCTA';
import {
  handleImageUpload,
  handleImageRemove,
  handleTextDataChange,
  checkIsCtaActive,
  handleCartUpdate,
  handleByNowClick,
} from '../utils/common';
import { OPERATION_TYPE } from '../utils/constant';

const InitialInputScreen = ({
  imageCount,
  textCount,
  images,
  setImages,
  tempImages,
  setTempImages,
  textData,
  setTextData,
  errorState,
  setErrorState,
  storeInfo,
  personalizedProduct,
  handleCart,
  productData,
  source,
  allImageMandatory,
  onBuyNowClick,
  cartData,
  handleAddPersonalizationToLocalState,
  personalizationLocalState,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      {!!imageCount && (
        <ImageUpload
          images={images}
          tempImages={tempImages}
          allImageMandatory={allImageMandatory}
          setImages={setImages}
          handleImageUpload={(e, idx) =>
            handleImageUpload(
              e,
              idx,
              images,
              setImages,
              setErrorState,
              tempImages,
              setTempImages
            )
          }
          handleImageRemove={(idx) =>
            handleImageRemove(idx, images, setImages, tempImages, setTempImages)
          }
        />
      )}
      {!!textCount && (
        <TextBox
          textCount={textCount}
          textData={textData[0]}
          handleTextDataChange={(e) =>
            handleTextDataChange(e, textCount, setTextData, setErrorState)
          }
        />
      )}
      {errorState && (
        <div className="tw-flex tw-items-center tw-gap-2">
          <ErrorIcon />
          <span className="tw-text-[0.875rem] tw-font-medium tw-text-[#9A0C00]">
            {errorState}
          </span>
        </div>
      )}
      {personalizedProduct?.editPersonalizationPopUp ? (
        <button
          className="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-border-none tw-bg-black tw-py-[0.75rem] tw-text-[0.875rem] tw-font-medium tw-text-[#fff]"
          style={{ backgroundColor: storeInfo?.theme?.colors?.primary_color || 'black' }}
          onClick={() =>
            handleCartUpdate(
              OPERATION_TYPE.SAVE,
              personalizationLocalState,
              personalizedProduct?.personalizationData?.index,
              null,
              images,
              textData,
              imageCount,
              textCount,
              allImageMandatory,
              personalizedProduct,
              handleCart,
              dispatch,
              productData,
              source,
              setImages,
              setTextData,
              setErrorState,
              cartData
            )
          }
        >
          Save Changes
        </button>
      ) : (
        (!!imageCount || !!textCount) && (
          <RenderProductCTA
            storeInfo={storeInfo}
            checkIsCtaActive={() => checkIsCtaActive(images, textData)}
            handleAddToCart={() =>
              handleCartUpdate(
                'add',
                personalizationLocalState,
                null,
                handleAddPersonalizationToLocalState,
                images,
                textData,
                imageCount,
                textCount,
                allImageMandatory,
                personalizedProduct,
                handleCart,
                dispatch,
                productData,
                source,
                setImages,
                setTextData,
                setErrorState,
                cartData
              )
            }
            onBuyNowClick={() =>
              handleByNowClick(
                images,
                textData,
                imageCount,
                textCount,
                allImageMandatory,
                onBuyNowClick,
                setImages,
                setTextData,
                setErrorState
              )
            }
            source={source}
          />
        )
      )}
    </>
  );
};

export default InitialInputScreen;
